.root {
	&.large {
		font-family: var(--hdsplus-typography-display-expressive-400-font-family);
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-400-letter-spacing
		);

		@media (--medium-up) {
			font-family: var(--hdsplus-typography-display-expressive-600-font-family);
			font-size: var(--hdsplus-typography-display-expressive-600-font-size);
			line-height: var(--hdsplus-typography-display-expressive-600-line-height);
			letter-spacing: var(
				--hdsplus-typography-display-expressive-600-letter-spacing
			);
		}

		@media (--large) {
			font-family: var(--hdsplus-typography-display-expressive-700-font-family);
			font-size: var(--hdsplus-typography-display-expressive-700-font-size);
			line-height: var(--hdsplus-typography-display-expressive-700-line-height);
			letter-spacing: var(
				--hdsplus-typography-display-expressive-700-letter-spacing
			);
		}
	}

	&.medium {
		font-family: var(--hdsplus-typography-display-expressive-300-font-family);
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-300-letter-spacing
		);

		@media (--medium-up) {
			font-family: var(--hdsplus-typography-display-expressive-500-font-family);
			font-size: var(--hdsplus-typography-display-expressive-500-font-size);
			line-height: var(--hdsplus-typography-display-expressive-500-line-height);
			letter-spacing: var(
				--hdsplus-typography-display-expressive-500-letter-spacing
			);
		}

		@media (--large) {
			font-family: var(--hdsplus-typography-display-expressive-600-font-family);
			font-size: var(--hdsplus-typography-display-expressive-600-font-size);
			line-height: var(--hdsplus-typography-display-expressive-600-line-height);
			letter-spacing: var(
				--hdsplus-typography-display-expressive-600-letter-spacing
			);
		}
	}

	&.small {
		font-family: var(--hdsplus-typography-display-expressive-200-font-family);
		font-size: var(--hdsplus-typography-display-expressive-200-font-size);
		line-height: var(--hdsplus-typography-display-expressive-200-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-200-letter-spacing
		);

		@media (--medium-up) {
			font-family: var(--hdsplus-typography-display-expressive-400-font-family);
			font-size: var(--hdsplus-typography-display-expressive-400-font-size);
			line-height: var(--hdsplus-typography-display-expressive-400-line-height);
			letter-spacing: var(
				--hdsplus-typography-display-expressive-400-letter-spacing
			);
		}
	}
}

.hasHeadingColor {
	& span {
		min-width: min-content;
		background: var(--heading-color);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}